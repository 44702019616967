import { useState } from 'react';
import {
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import User from '../user.interface';
import { mapRole, Roles } from '../../../utils/roles';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import maskNumber from '../../../utils/maskNumber';

interface UsersTableRowProps {
  user: User;
  openUpdateUserDialog: (user: User) => void;
}

function UsersTableRow ({ user, openUpdateUserDialog }: UsersTableRowProps) {
  const message = useMessage();

  const [role, setRole] = useState(user.role);

  async function updateRole (role: string) {
    try {
      await axiosInstance.put(API_ROUTES.USERS_UPDATE_ROLE, {
        cpf: user.cpf,
        role,
      });
      setRole(role);
    } catch (error: any) {
      message.setMessage({
        text:
          error.response?.data.message ||
          'Não foi possível alterar as permissões',
        type: 'error',
      });
    }
  }

  return (
    <TableRow>
      <TableCell align="center">{user.id}</TableCell>
      <TableCell align="center">{user.name}</TableCell>
      <TableCell align="center">
        {maskNumber(user.cpf, '###.###.###-##')}
      </TableCell>
      <TableCell align="center">
        <Select
          value={role}
          onChange={(e) => {
            updateRole(e.target.value as string);
          }}
          style={{ textAlign: 'left', width: 110 }}
        >
          {Object.values(Roles).map((role) => (
            <MenuItem key={role} value={role}>
              {mapRole(role)}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="center">
        {user.city.id}
      </TableCell>
      <TableCell align="center">
        {user.city.name}
      </TableCell>
      <TableCell align="center">
        {user.city.connection_name}
      </TableCell>
      <TableCell align="center">
        {user.city.sigus_connection_name}
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Clique aqui para atualizar as informações desse usuário">
          <IconButton onClick={() => 
            openUpdateUserDialog(user)}>
          <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default UsersTableRow;
