import { 
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { API_ROUTES, axiosInstance } from "api";
import { useMessage } from "context/message/messageContext";
import useCities, { City } from "hooks/useCities";
import { useEffect, useState } from "react";
import { convertDateFromPtBRToISO8601, convertDateFromISO8601ToPtBR } from "utils/date_converter";
import maskNumber from "utils/maskNumber";
import { Roles } from "utils/roles";
import User, { Professional, Establishment } from "../user.interface";
import useEstablishmentsUpdate from "./hooks/useEstablishmentsUpdate";
import useProfessionalsUpdate from "./hooks/useProfessionalsUpdate";

interface Props {
    open: boolean;
    user: User;
    onClose: () => void;
}

function UpdateUserDialog({ open, user, onClose }: Props) {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [livingCity, setLivingCity] = useState<City | null>(null);
    const [birthDate, setBirthDate] = useState('');
    const [email, setEmail] = useState('');
    const [susCard, setSusCard] = useState('');
    const [phone, setPhone] = useState('');
    const [motherName, setMotherName] = useState('');
    const [role, setRole] = useState<Roles>(Roles.Padrao);
    const [staff, setStaff] = useState(false);
    const [professional, setProfessional] = useState<Professional | null>(null);
    const [establishment, setEstablishment] = useState<Establishment | null>(null);

    const { cities } = useCities();
    const { professionals } = useProfessionalsUpdate();
    const { establishments } = useEstablishmentsUpdate();

    const message = useMessage();

    function getErrorType(key: string) {
        switch (key) {
            case 'name':
                return 'Nome: ';
            case 'cpf':
                return 'Cpf: ';
            case 'email':
                return 'Email: ';
            case 'role':
                return 'Tipo: ';
            case 'is_staff':
                return 'Funcionário: ';
            case 'mother_name':
                return 'Nome da Mãe: ';
            case 'establishment':
                return 'Estabelecimento: ';
            case 'card_sus':
                return 'Cartão do SUS: ';
            case 'phone':
                return 'Telefone: ';
            case 'birth_date':
                return 'Data de Nascimento: ';
            case 'birth_city':
                return 'Cidade de Nascimento: ';
            default:
                return '';
        }
    }

    async function onSubmit() {
        try {
            await axiosInstance.put(
                API_ROUTES.ADMINISTRATION_USERS_UPDATE.replace(':id', id.toString()),
                {
                    name,
                    cpf,
                    email,
                    role,
                    is_staff: staff,
                    birth_city: livingCity?.name,
                    living_city: livingCity?.id,
                    mother_name: motherName || null,
                    establishment: establishment?.id,
                    card_sus: susCard?.replace(/\D/g, '') || undefined,
                    birth_date: convertDateFromPtBRToISO8601(birthDate),
                    phone: phone.replace(/\D/g, ''),
                });

            message.setMessage({
                type: 'success',
                text: `Usuário atualizado com sucesso`,
            });
        
            onClose();
        } catch(error: any) {
            let msg = 'Ocorreu um erro ao atualizar o usuário';

            if (error.response?.data) {
                const err = Object.entries(error.response.data)[0];
                const begin = getErrorType(err[0]);
                if (begin) {
                msg = `${begin} ${(err[1] as string)[0]}`;
                }
            }

            message.setMessage({
                type: 'error',
                text: msg,
            });
        }
    }

    useEffect(() => {
        setId(user?.id || 0);
        setName(user?.name || '');
        setCpf(user?.cpf || '');
        setBirthDate(convertDateFromISO8601ToPtBR(user?.birth_date) || '');
        setLivingCity(user?.city || null);
        setEmail(user?.email || '');
        setSusCard(user?.card_sus || '');
        setPhone(user?.phone || '');
        setMotherName(user?.mother_name || '');
        setRole(user?.role as Roles || Roles.Padrao);
        setStaff(user?.is_staff || false);
        setProfessional(user?.professional || null);
        setEstablishment(user?.establishment || null);        
    }, [open, user]);

    return (
        <Dialog open={open}>
            <DialogTitle>Atualizar Usuário</DialogTitle>
            <DialogContent dividers>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                    <TextField
                        variant="outlined"
                        label="Nome"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    <div style={{ display: 'flex', gap: 8 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="CPF"
                            value={maskNumber(cpf, '###.###.###-##')}
                            onChange={(e) => {
                                setCpf(e.target.value.replace(/\D/g, '').substring(0, 11));
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Cartão do SUS"
                            value={maskNumber(susCard, '### #### #### ####')}
                            onChange={(e) => {
                                setSusCard(e.target.value.substring(0, 18));
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', gap: 8 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="E-mail"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Data de Nascimento"
                            value={maskNumber(birthDate, '##/##/####')}
                            onChange={(e) => {
                                setBirthDate(e.target.value.substring(0, 10));
                            }}
                        />
                    </div>

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Telefone"
                        value={maskNumber(phone, '(##) # ####-####')}
                        onChange={(e) => {
                        setPhone(e.target.value.substring(0, 16));
                        }}
                    />

                    <div style={{ display: 'flex', gap: 8 }}>
                        <TextField
                        fullWidth
                        variant="outlined"
                        label="Nome da mãe"
                        value={motherName}
                        onChange={(e) => {
                            setMotherName(e.target.value);
                        }}
                        />
                    </div>

                    <FormControl variant="outlined">
                        <InputLabel>Cidade</InputLabel>
                        <Select
                            label="Cidade"
                            value={livingCity?.name || ""}
                            onChange={(e) => {
                                if (livingCity) {
                                    livingCity.name = e.target.value as string
                                }                                
                            }}
                            style={{ textAlign: 'left', width: '50%' }}
                        >
                            <MenuItem value={livingCity?.name || ""}>{livingCity?.name}</MenuItem>
                                {cities.map((city) => (
                                    <MenuItem
                                        key={city.id}
                                        value={city.name}                    
                                        onClick={() => {
                                            setLivingCity(city);
                                        }}
                                    >
                                {city.name}
                            </MenuItem>
                            ))}
                        </Select>                        
                    </FormControl>

                    <FormControl variant="outlined">
                        <InputLabel>Estabelecimento</InputLabel>
                        <Select
                            label="Estabelecimento"
                            value={establishment?.nome_fanta || "Selecione"}
                            onChange={(e) => {
                                if (establishment) {
                                    establishment.nome_fanta = e.target.value as string
                                }                                
                            }}
                        >
                            <MenuItem value={establishment?.nome_fanta || "Selecione"}>{establishment?.nome_fanta || "Selecione"}
                            </MenuItem>
                            {establishments.map((data) => (
                                <MenuItem
                                    key={data.id}
                                    value={data.nome_fanta}
                                    onClick={() => {
                                        setEstablishment(data);
                                    }}
                                >
                                    {data.nome_fanta}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{ display: 'flex', gap: 8 }}>
                        <FormControl variant="outlined" style={{ minWidth: 150 }}>
                            <InputLabel>Tipo</InputLabel>
                            <Select
                                label="Tipo"
                                value={role}
                                onChange={(e) => {
                                    setRole(e.target.value as Roles);
                                }}
                            >
                                <MenuItem value={Roles.Enfermeiro}>Enfermeiro</MenuItem>
                                <MenuItem value={Roles.Medico}>Médico</MenuItem>
                                <MenuItem value={Roles.Gestor}>Gestor</MenuItem>
                                <MenuItem value={Roles.Padrao}>Cidadão</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={staff}
                                    onChange={(e) => {
                                        setStaff(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            }
                            label="Funcionário"
                        />

                        <Autocomplete
                            fullWidth
                            autoHighlight
                            value={professional}
                            options={professionals}
                            onChange={(_e, val) => {
                                setProfessional(val);
                            }}
                            noOptionsText="Nenhum profissional encontrado"
                            getOptionLabel={(option) => option.nome_prof}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Profissional"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}>
                    Cancelar
                </Button>
                <Button variant="outlined" color="primary" onClick={onSubmit}>
                    Atualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateUserDialog;
