import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import { useState, createContext, useContext } from 'react';

interface ContextProps {
  message: Message;
  setMessage: (message: Message) => void;
}

export type Message = {
  text: string;
  type: AlertProps['severity'];
  autoHideDuration?: number | null;
  handleClose?: (event?: object, reason?: string) => void;
  anchor?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };
} | null;

const MessageContext = createContext<ContextProps>({} as ContextProps);

function ProvideMessage({ children }: any) {
  const [message, setMsg] = useState<Message>(null);
  const [isMessageOpen, setMsgOpen] = useState(false);

  function setMessage(message: Message) {
    setMsg(message);
    setMsgOpen(!!message);
  }

  function onExited() {
    setMessage(null);
  }

  function handleClose(event?: any, reason?: string) {
    if (message?.handleClose) {
      message.handleClose(event, reason);
    }
    setMsgOpen(false);
  }

  const autoHideDuration =
    typeof message?.autoHideDuration === 'undefined'
      ? 8000
      : message.autoHideDuration;

  return (
    <MessageContext.Provider value={{ message, setMessage }}>
      <>
        <Snackbar
          open={isMessageOpen}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          onExited={onExited}
          anchorOrigin={message?.anchor}
        >
          <Alert onClose={handleClose} severity={message?.type}>
            {message?.text}
          </Alert>
        </Snackbar>
        {children}
      </>
    </MessageContext.Provider>
  );
}

export function useMessage() {
  return useContext(MessageContext);
}

export default ProvideMessage;
