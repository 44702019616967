import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';
import { Search, GetApp, Visibility } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import TablePaginationActions from 'components/TablePaginationActions';
import { Message } from 'context/message/messageContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../../api';
import { useMessage } from '../../../../../context/message';
import useCsvExporter from '../../../../../hooks/useCsvExporter';
import FilterModal from './FilterModal';
import formatTable from './utils/formatRows';

const rowsPerPage = 15;
export let showMessage = (message: Message) => {}

function AllCitizens() {
  const exporter = useCsvExporter();
  const message = useRef(useMessage());
  const source = useRef<CancelTokenSource | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rows, setRows] = useState<any[][]>([]);
  const [loading, setLoading] = useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchFilters, setSearchFilters] = useState<any>({});
  const [openFilters, setOpenFilters] = useState(false);
  const [columns, setColumns] = useState<string[]>([
    'Equipe',
    'MA',
    'Cidadão',
    'CNS',
    'CPF',
  ]);

  useEffect(() => {
    showMessage = message.current.setMessage
  }, [])


  function getFilters(searchFilters: any) {
    const filters = {} as any;
    Object.keys(searchFilters).forEach(
      (filter) => (filters[filter] = searchFilters[filter] || undefined),
    );
    return filters;
  }

  async function exportToCsv() {
    exporter.exportToCsv(
      API_ROUTES.CSV_EXPORTER_CITIZENS,
      'cidadaos.csv',
      getFilters(searchFilters),
    );
  }

  const fetchAllCitizens = useCallback(async () => {
    source.current?.cancel();
    source.current = axios.CancelToken.source();

    setRows([]);
    setLoading(true);
    setInitialLoad(false);

    try {
      const filters = getFilters(searchFilters);

      const res = await axiosInstance.get(API_ROUTES.ESUS_CITIZENS, {
        cancelToken: source.current!.token,
        params: {
          page: page + 1,
          page_size: rowsPerPage,
          ...filters,
        },
      });

      const { rows, columns } = formatTable(res.data.results);

      setColumns(columns);
      setRows(rows);
      setCount(res.data.count);
      setLoading(false);
      setCanFetch(false);
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      }

      setRows([]);
      setLoading(false);
      setCanFetch(false);

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.detail ||
          'Ocorreu um erro ao carregar os cidadões.',
      });
    }
  }, [page, searchFilters]);

  useEffect(() => {
    if (canFetch) {
      fetchAllCitizens();
    }
  }, [canFetch, fetchAllCitizens]);

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  return (
    <>
      <FilterModal
        searchFilters={searchFilters}
        setSearchFilters={(searchFilters: any) => {
          setSearchFilters(searchFilters);
          setCanFetch(true);
        }}
        open={openFilters}
        setOpen={setOpenFilters}
      />

      <Button
        color="primary"
        variant="outlined"
        style={{ height: 56, marginBottom: 16, marginRight: 16 }}
        startIcon={<Visibility />}
        disabled={loading}
        onClick={() => {
          setOpenFilters(true);
        }}
      >
        Mostrar Filtros
      </Button>

      <Button
        style={{
          height: 56,
          marginBottom: 16,
          marginRight: 16,
        }}
        color="primary"
        variant="outlined"
        startIcon={<Search />}
        onClick={() => {
          setPage(0);
          setCanFetch(true);
        }}
        disabled={loading}
      >
        Pesquisar
      </Button>

      <Button
        style={{
          height: 56,
          marginBottom: 16,
        }}
        color="primary"
        variant="outlined"
        startIcon={
          exporter.isExporting ? (
            <CircularProgress size={14} disableShrink />
          ) : (
            <GetApp />
          )
        }
        onClick={exportToCsv}
        disabled={exporter.isExporting}
      >
        Exportar
      </Button>

      <TableContainer
        component={Paper}
        // This value has to be calculated manually if you add or remove another component in the page
        // TODO: find a way to calculate the max height automatically
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col, idx) => (
                <TableCell
                  key={idx}
                  align="center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow key={idx}>
                {row.map((data, idx) => (
                  <TableCell
                    key={idx}
                    align="center"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {data}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {(loading || rows.length === 0) && (
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            !initialLoad && 'Nenhum cidadão encontrado'
          )}
        </div>
      )}

      <TablePagination
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
          setCanFetch(true);
        }}
      />
    </>
  );
}

export default AllCitizens;
