import axios, { AxiosResponse } from 'axios';
import { useState, useRef, useEffect } from 'react';
import { axiosInstance, API_ROUTES } from '../../../../api';
import { useMessage } from '../../../../context/message';
import { Establishment } from '../../user.interface';

function useEstablishmentsUpdate() {
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefresh] = useState(0);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);

  const message = useRef(useMessage());

  function refresh() {
    setRefresh((refresh) => refresh + 1);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setEstablishments([]);

    (async () => {
      try {
        const request: AxiosResponse<Establishment[]> = await axiosInstance.get(
          API_ROUTES.ESTABLISHMENTS,
          {
            cancelToken: source.token,
          },
        );

        setEstablishments(request.data);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.message ||
            'Ocorreu um erro ao carregar a lista de estabelecimentos.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [refreshCount]);

  return { establishments, loading, refresh };
}

export default useEstablishmentsUpdate;
