import { useRef, useState, useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useStyles } from './cns.styles';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import axios, { CancelTokenSource } from 'axios';
import { convertDateFromISO8601ToPtBR } from '../../utils/date_converter';
import maskNumber from '../../utils/maskNumber';
import { Search, Today, Delete, PictureAsPdf } from '@material-ui/icons';
import PageHeader from '../../components/PageHeader';
import { saveAs } from 'file-saver';

enum FilterTypes {
  CPF_ONLY,
  CNS_ONLY,
  BIRTH_DATE,
  MOTHERS_NAME,
}

export interface PatientData {
  cpf: string;
  name: string;
  cns: string;
  addresses: {
    city: string;
    state: string;
    postalCode: string;
    country: string;
    streetName: string;
    houseNumber: string;
    streetNameType: string;
  }[];
  birthDate: string;
  gender: string;
  contacts: string[];
}

export function getInitialPatientData(): PatientData {
  return {
    cpf: '',
    name: '',
    cns: '',
    birthDate: '',
    gender: '',
    addresses: [],
    contacts: [],
  };
}

function CNS() {
  const classes = useStyles();
  const message = useMessage();

  const [patientData, setPatientData] = useState(getInitialPatientData());
  const [cpf, setCpf] = useState('');
  const [cns, setCns] = useState('');
  const [name, setName] = useState('');
  const [mothersName, setMothersName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState<FilterTypes>(
    FilterTypes.CPF_ONLY,
  );
  const source = useRef<CancelTokenSource | null>(null);

  const addresses = useMemo(() => {
    return patientData.addresses.filter(
      (ad) => ad.streetName && ad.houseNumber,
    );
  }, [patientData.addresses]);

  function getGender(gender: string) {
    switch (gender) {
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Feminino';
    }
    return gender;
  }

  function getParams() {
    switch (filterType) {
      case FilterTypes.CPF_ONLY:
        return {
          cpf,
        };
      case FilterTypes.CNS_ONLY:
        return { cns };
      case FilterTypes.MOTHERS_NAME:
        return {
          person_name: name,
          mother_name: mothersName,
        };
      case FilterTypes.BIRTH_DATE:
        return {
          person_name: name,
          birthdate: birthDate.replace(/\D/g, ''),
        };
    }
  }

  function isValid() {
    const params = getParams();
    const entries = Object.entries(params) as [[string, string]];

    return entries.every((entry) => {
      switch (entry[0]) {
        case 'cpf':
          return entry[1].length === 11;
        case 'cns':
          return entry[1].length === 15;
        case 'person_name':
        case 'mother_name':
        case 'birthdate':
          return entry[1].length > 0;
        default:
          return true;
      }
    });
  }

  function cleanFilters() {
    setCpf('');
    setCns('');
    setName('');
    setMothersName('');
    setBirthDate('');
  }

  async function handleSearch() {
    if (!isValid()) {
      message.setMessage({
        type: 'warning',
        text: 'Preencha todos os campos corretamente',
      });
      return;
    }

    if (source.current) {
      source.current.cancel();
    }

    setLoading(true);

    try {
      source.current = axios.CancelToken.source();
      setPatientData(getInitialPatientData());

      const response = await axiosInstance.post(
        API_ROUTES.PROXY_CNS,
        getParams(),
        { cancelToken: source.current.token },
      );

      setPatientData(response.data);
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          'Ocorreu um erro ao buscar os dados do paciente',
        autoHideDuration: null,
      });
    } finally {
      setLoading(false);
    }
  }

  async function downloadCNS() {
    try {
      const response = await axiosInstance.post(
        API_ROUTES.PROXY_PRINT_CNS_SEARCH,
        {
          patient: patientData,
        },
      );
      const blob = new Blob([Buffer.from(response.data, 'base64')], {
        type: 'application/pdf',
      });
      saveAs(blob, 'cns.pdf');
    } catch (error: any) {
      let message = error.response?.data?.message;

      if (!message) {
        const errorMsg = Object.entries(error.response?.data).map(
          ([key, value]: any) => {
            return `${key}: ${value.join(',')}`;
          },
        );

        message = errorMsg.join('  ');
      }
      message.setMessage({
        type: 'error',
        text: message || 'Ocorreu um erro ao salvar cns do paciente',
        autoHideDuration: null,
      });
    }
  }

  return (
    <>
      <PageHeader title="Cartão Nacional de Saúde" />

      <div
        className={classes.filtersContainer}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !loading) {
            handleSearch();
          }
        }}
      >
        <FormControl variant="outlined">
          <InputLabel>Tipo de pesquisa</InputLabel>
          <Select
            className={classes.filterSelect}
            disabled={loading}
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value as FilterTypes);
            }}
            label="Tipo de pesquisa"
          >
            <MenuItem value={FilterTypes.CPF_ONLY}>CPF</MenuItem>
            <MenuItem value={FilterTypes.CNS_ONLY}>CNS</MenuItem>
            <MenuItem value={FilterTypes.BIRTH_DATE}>
              Nome + Data de Nascimento
            </MenuItem>
            <MenuItem value={FilterTypes.MOTHERS_NAME}>
              Nome + Nome da Mãe
            </MenuItem>
          </Select>
        </FormControl>

        {filterType === FilterTypes.CPF_ONLY && (
          <TextField
            className={classes.cpfField}
            disabled={loading}
            variant="outlined"
            label="CPF"
            value={maskNumber(cpf, '###.###.###-##')}
            onChange={(e) =>
              setCpf(e.target.value.replace(/\D/g, '').substring(0, 11))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}

        {filterType === FilterTypes.CNS_ONLY && (
          <TextField
            className={classes.cnsField}
            disabled={loading}
            variant="outlined"
            label="CNS"
            value={maskNumber(cns, '### #### #### ####')}
            onChange={(e) =>
              setCns(e.target.value.replace(/\D/g, '').substring(0, 15))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}

        {[FilterTypes.BIRTH_DATE, FilterTypes.MOTHERS_NAME].includes(
          filterType,
        ) && (
          <TextField
            disabled={loading}
            variant="outlined"
            label="Nome"
            value={name}
            onChange={(e) => setName(e.target.value.toUpperCase())}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}

        {filterType === FilterTypes.MOTHERS_NAME && (
          <TextField
            disabled={loading}
            variant="outlined"
            label="Nome da Mãe"
            value={mothersName}
            onChange={(e) => setMothersName(e.target.value.toUpperCase())}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}

        {filterType === FilterTypes.BIRTH_DATE && (
          <TextField
            className={classes.dateField}
            disabled={loading}
            variant="outlined"
            label="Data de Nascimento"
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Today />
                </InputAdornment>
              ),
            }}
          />
        )}

        <Button
          variant="outlined"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
          startIcon={<Search />}
        >
          Procurar
        </Button>

        <Button
          variant="outlined"
          color="default"
          onClick={cleanFilters}
          disabled={loading}
          startIcon={<Delete />}
        >
          Limpar
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={downloadCNS}
          disabled={patientData.name ? false : true}
          startIcon={<PictureAsPdf />}
        >
          Exportar CNS (PDF)
        </Button>
      </div>

      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={64} />
        </div>
      )}

      {patientData.name && (
        <Card className={classes.userCard}>
          <CardContent>
            <Typography variant="h5" className={classes.userInfoTitle}>
              {patientData.name}
            </Typography>
            <div className={classes.userInfoContainer}>
              <TextField
                variant="outlined"
                label="CPF"
                value={
                  patientData.cpf
                    ? maskNumber(patientData.cpf, '###.###.###-##')
                    : 'Não Informado'
                }
              />
              <TextField
                variant="outlined"
                label="CNS"
                value={
                  patientData.cns
                    ? maskNumber(patientData.cns, '### #### #### ####')
                    : 'Não Informado'
                }
              />
              <TextField
                variant="outlined"
                label="Data de nascimento"
                value={
                  patientData.birthDate
                    ? convertDateFromISO8601ToPtBR(
                        patientData.birthDate.replace(
                          /(\d{4})(\d{2})(\d{2})/,
                          '$1-$2-$3',
                        ),
                      )
                    : 'Não Informado'
                }
              />
              <TextField
                variant="outlined"
                label="Sexo"
                value={getGender(patientData.gender)}
              />
            </div>

            {patientData.contacts.length > 0 && (
              <>
                <Typography variant="h6" className={classes.listHeader}>
                  Contatos
                </Typography>
                <div className={classes.listContainer}>
                  {patientData.contacts.map((contact, index) => (
                    <TextField key={index} variant="outlined" value={contact} />
                  ))}
                </div>
              </>
            )}

            {addresses.length > 0 && (
              <>
                <Typography variant="h6" className={classes.listHeader}>
                  Endereços
                </Typography>
                <div className={classes.listContainer}>
                  {patientData.addresses
                    .filter((ad) => ad.streetName && ad.houseNumber)
                    .map((address, index) => (
                      <TextField
                        key={index}
                        variant="outlined"
                        value={`${address.streetName}, ${address.houseNumber}`}
                      />
                    ))}
                </div>
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default CNS;
