import { convertDateFromISO8601ToPtBR } from '../../../../../../utils/date_converter';
import CreditCard from '@material-ui/icons/CreditCard';
import { API_ROUTES, axiosInstance } from 'api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { showMessage } from '../AllCitizens';
import { useState } from 'react';

interface TableData {
  title: string;
  key: string;
  transform?: (val: any, data?: any) => any;
}

const tableData: TableData[] = [
  { title: 'Equipe', key: 'equipe' },
  { title: 'MA', key: 'ma' },
  { title: 'Nome', key: 'cidadao' },
  { title: 'Nome Social', key: 'nome_social' },
  { title: 'Nome da Mãe', key: 'no_mae' },
  { title: 'Profissional', key: 'profissional_nome' },
  {
    title: 'CNS',
    key: 'nu_cns',
    transform: (val, data) => {
      return val ? (
        <>
          {val}
          {data.nome_social ? (
            <DialogCNS idCidadao={data.co_cidadao} />
          ) : (
            <Button
              onClick={() => {
                printCitizenCNS(data.co_cidadao, false);
              }}
            >
              <CreditCard />
            </Button>
          )}
        </>
      ) : null;
    },
  },
  { title: 'CPF', key: 'nu_cpf' },
  { title: 'Idade', key: 'idade', transform: (val) => (+val).toString() },
  {
    title: 'Data de nascimento',
    key: 'dt_nascimento',
    transform: convertDateFromISO8601ToPtBR,
  },
  {
    title: 'Data de atualização',
    key: 'dt_atualizado',
    transform: (val) => convertDateFromISO8601ToPtBR(val.split(' ')[0]),
  },
  { title: 'Sexo', key: 'sexo' },
  { title: 'Celular', key: 'celular' },
  { title: 'NIS', key: 'nis' },
  { title: 'Gestante', key: 'ges' },
  { title: 'Álcool', key: 'alcool' },
  { title: 'Deficiente', key: 'deficiente' },
  { title: 'Diabete', key: 'diabete' },
  { title: 'Fumante', key: 'fumante' },
  { title: 'Hanseniase', key: 'hanseniase' },
  { title: 'Hipertensão Arterial', key: 'hipertensao_arterial' },
  { title: 'Morador de rua', key: 'morador_rua' },
  { title: 'Tuberculose', key: 'tuberculose' },
];

const DialogCNS = ({ idCidadao }: { idCidadao: number }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        style={{ marginLeft: '0.5rem', padding: '0', width: 'fit-content' }}
        onClick={() => setOpen(true)}
      >
        <CreditCard />
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Usar nome social</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Deseja utilizar o nome social?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              printCitizenCNS(idCidadao, false);
              setOpen(false);
            }}
            color="secondary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              printCitizenCNS(idCidadao, true);
              setOpen(false);
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

async function printCitizenCNS(id: number, useSocialName: boolean) {
  try {
    const response = await axiosInstance.get(
      API_ROUTES.PROXY_PRINT_CITIZEN_CNS + id,
      { params: { useSocialName } },
    );

    const blob = new Blob([Buffer.from(response.data, 'base64')], {
      type: 'application/pdf',
    });
    saveAs(blob, 'cns.pdf');
  } catch (error: any) {
    let message = error.response?.data?.message;

    if (!message) {
      const errorMsg = Object.entries(error.response?.data).map(
        ([key, value]: any) => {
          return `${key}: ${value.join(',')}`;
        },
      );

      message = errorMsg.join('  ');
    }

    showMessage({
      type: 'error',
      text: message || 'Ocorreu um erro ao gerar o CNS do cidadão',
      autoHideDuration: null,
    });
  }
}

function formatTable(data: any[]) {
  const colsList = tableData.map((data) => ({
    key: data.key,
    transform: data.transform,
  }));

  const rows = data.map((result) => {
    const cols: string[] = [];
    colsList.forEach((col) => {
      const val = result[col.key];
      cols.push((col.transform ? col.transform(val, result) : val) || '--');
    });
    return cols;
  });

  return { rows, columns: tableData.map((data) => data.title) };
}

export default formatTable;
