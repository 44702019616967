export const API_URL = process.env.REACT_APP_BASE_URL;
export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

export enum API_ROUTES {
  LOGIN = 'account/login/',
  LOGIN_2FA = 'account/login/2fa/',
  REFRESH_TOKEN = 'account/refresh-token/',
  PASSWORD_CHANGE = 'account/change-password/',
  PASSWORD_RECOVERY = 'account/recovery/',
  PASSWORD_RECOVERY_TOKEN = 'account/recovery/generate-token/',
  PASSWORD_RECOVERY_CONFIRM = 'confirm-password/',

  CITY_CHANGE_PICTURE = 'city/logo/',
  CITY_UPDATE_DETAILS = 'city/',

  PROFILE = 'account/profile/',
  PROFILE_PICTURE = 'account/profile-picture/',

  SOLICITATIONS = 'solicitations/',
  SOLICITATIONS_STATISTICS = 'solicitations/summary/',
  SOLICITATIONS_CALL_PATIENT = 'solicitations/call_patient/:id/',
  SOLICITATIONS_FINISH = 'solicitations/finish/',
  SOLICITATIONS_FORWARD = 'solicitations/forward/',
  SOLICITATIONS_PRESCRIBE = 'solicitations/prescribe/',

  ESTABLISHMENTS = 'establishments/',
  ESTABLISHMENTS_EDIT = 'establishments/:id/',

  SIGN_PRESCRIPTION = 'signature/sign_pdf/',

  ESUS_INDICATORS = 'esus/indicators/',
  ESUS_DASHBOARD = 'esus/dashboard/',
  ESUS_ATTENDANCE_CONSOLIDATED = 'esus/attendance/consolidated/',
  ESUS_ATTENDANCE_PROCEDURES = 'esus/attendance/procedures/',
  ESUS_ATTENDANCE_DAYS_WORKED = 'esus/attendance/days-worked/',
  ESUS_CITIZENS = 'esus/citizen/',
  ESUS_CITIZENS_DUPLICATED = 'esus/citizen-duplicated/',
  ESUS_CITIZENS_DUPLICATED_DETAILS = 'esus/citizen-duplicated/details/',
  ESUS_CITIZENS_MICRO_AREAS = 'esus/citizen/micro-areas/',
  ESUS_TEAMS = 'esus/dim-teams/',
  ESUS_PROFESSIONALS = 'esus/dim-professionals/',

  USERS_LIST = 'account/users/',
  USERS_UPDATE_ROLE = 'account/user/',
  USERS_CREATE = 'account/user-create/',
  USERS_CONFIRM = 'account/user-create/confirm/',
  USERS_UPDATE = 'account/user-update/:id/',
  PROFESSIONALS = 'account/professionals/',
  PROFESSIONALS_EDIT = 'account/professionals/:id/',
  DOCTORS_AND_NURSES = 'account/doctors-nurses/',

  SYMPTOMS = 'symptoms/',
  SYMPTOMS_EDIT = 'symptoms/:id/',
  CONDITIONS = 'conditions/',
  CONDITIONS_EDIT = 'conditions/:id/',

  IMPORTS_LIST = 'importer/imports/',
  IMPORTS_CNES = 'importer/cnes/',
  IMPORTS_CNES_ONLINE = 'importer/cnes-online/',
  IMPORTS_GEOLOCATION = 'importer/import-geolocation/',
  IMPORTS_ESTABLISHMENTS = 'importer/establishments/',
  IMPORTS_ESTABLISHMENTS_ONLINE = 'importer/establishments-online/',
  IMPORTS_PROFESSIONAL_LINKS = 'importer/professional-links/',
  IMPORTS_PROFESSIONAL_LINKS_ONLINE = 'importer/professionals-online/',
  IMPORTS_SIGTAP = 'sigtap/import/',
  IMPORTS_SIGTAP_PROCEDURES = 'sigtap/procedures/',

  CSV_EXPORTER_CITIZENS = 'esus/csv-exporter/citizen/',
  CSV_EXPORTER_CITIZENS_DUPLICATED = 'esus/csv-exporter/citizen-duplicated/',
  CSV_EXPORTER_INDICATORS = 'esus/indicators/csv-exporter/',
  CSV_EXPORTER_ATTENDANCE_CONSOLIDATED = 'esus/attendance/consolidated/csv-exporter/',
  CSV_EXPORTER_ATTENDANCE_PROCEDURES = 'esus/attendance/procedures/csv-exporter/',
  CSV_EXPORTER_ATTENDANCE_DAYS_WORKED = 'esus/attendance/days-worked/csv-exporter/',

  EXPORTER_BPA = 'exporter/bpa/',
  EXPORTER_BPA_FORM_DATA = 'exporter/bpa/form-data/',
  EXPORTER_BPA_CITIES_LIST = 'exporter/bpa/cities-list/',

  PROXY = 'proxy/',
  PROXY_CNS = 'proxy/cns/',
  PROXY_PRINT_CNS = 'proxy/cns/print',
  PROXY_PRINT_CNS_SEARCH = 'proxy/cns/print/lookup',
  PROXY_PRINT_CITIZEN_CNS = 'proxy/cns/print/citizen/',
  CNES_UFS = 'http://cnes.datasus.gov.br/services/estados',
  CNES_MUNICIPIOS = 'http://cnes.datasus.gov.br/services/municipios',
  CNES_ESTABELECIMENTOS = 'http://cnes.datasus.gov.br/services/estabelecimentos',
  CNES_ESTABELECIMENTOS_PROFISSIONAIS = 'http://cnes.datasus.gov.br/services/estabelecimentos-profissionais',

  VACCINES = 'vaccines/',
  VACCINES_CREATE = 'vaccines/create/',
  VACCINES_EDIT = 'vaccines/:id/',
  VACCINES_DELETE = 'vaccines/delete/:id/',
  VACCINES_TARGET_GROUPS = 'vaccines/target_groups/',

  ADMINISTRATION_USERS = 'administration/users/',
  ADMINISTRATION_USERS_CREATE = 'administration/users/create/',
  ADMINISTRATION_USERS_UPDATE = 'administration/users/:id/',
  ADMINISTRATION_CITIES = 'administration/cities/',
  ADMINISTRATION_CITIES_CREATE = 'administration/cities/create/',
  ADMINISTRATION_CITIES_SELECT = 'administration/cities/select/',
}
