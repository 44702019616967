import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance, API_ROUTES } from '../../../../api';
import { useMessage } from '../../../../context/message';
import { Professional } from '../../user.interface';

function useProfessionalsUpdate() {
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();
    setProfessionals([]);

    (async () => {
      try {
        const response = await axiosInstance.get<Professional[]>(API_ROUTES.PROFESSIONALS, {
          cancelToken: source.token,
          params: {
            no_page: true,
          },
        });

        setProfessionals(
          response.data.map(
            (professional: Professional): Professional => ({
              id: professional.id,
              nome_prof: professional.nome_prof,
            }),
          ),
        );
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setProfessionals([]);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.message ||
            'Ocorreu um erro ao carregar a lista de profissionais.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return { professionals };
}

export default useProfessionalsUpdate
